import React from "react";
import LanguageSelector from "./LanguageSelector";

export const Navigation = (props) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{ background: "#fbf7ee" }}
    >
      <div className="container shadow-lg p-3 mb-5 bg-body-tertiary rounded">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right" style={navRightStyle}>
            <li style={navItemStyle}>
              <a href="#portfolio" className="page-scroll">
                Iniciar Sesión
              </a>
            </li>
            <li style={navItemStyle}>
              <LanguageSelector />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const navRightStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

const navItemStyle = {
  margin: '0 10px', // Espacio entre los elementos
};

// Media queries para el diseño responsivo
const styles = `
  @media (max-width: 768px) {
    .navbar-nav.navbar-right {
      flex-direction: column;
      align-items: center;
    }

    .navbar-nav.navbar-right li {
      display: block;
      margin: 10px 0;
    }
  }
`;

// Añadir los estilos responsivos al documento
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Navigation;
