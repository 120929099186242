import React, { useState, useContext, useEffect } from "react";
import { MyContext } from "../context/MainContext"; // Asegúrate de proporcionar la ruta correcta al contexto

const LanguageSelector = () => {
  const [selected, setSelected] = useState("ES"); // Establece el estado inicial en "ES"
  const [myState, setMyState, language, setLanguage] = useContext(MyContext); // Utilizamos el contexto

  useEffect(() => {
    // Cuando el componente se monta, actualizamos el idioma en el contexto
    setLanguage(selected.toLowerCase());
  }, [selected, setLanguage]);

  const toggleLanguage = () => {
    const newLanguage = selected === "ES" ? "US" : "ES";
    setSelected(newLanguage);
  };

  const flagImages = {
    US: "https://flagcdn.com/w320/us.png", // URL de la bandera de EE. UU.
    ES: "https://flagcdn.com/w320/es.png", // URL de la bandera de España
  };

  return (
    <button onClick={toggleLanguage} style={buttonStyle}>
      <img src={flagImages[selected]} alt={`Flag of ${selected}`} style={flagStyle} />
    </button>
  );
};

const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
};

const flagStyle = {
  width: '36px', // Ajusta el tamaño para que sea más visible
  height: '26px',
  borderRadius: '5px', // Agrega un ligero borde redondeado
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Agrega sombra para darle un aspecto más destacado
};

export default LanguageSelector;
